const REFERRER_COOKIE_NAME = 'builder.initialReferrer';

export function getCookie(name: string): string | null {
  name = name + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieParts = decodedCookie.split(';');
  for (let i = 0; i < cookieParts.length; i++) {
    let cookiePart = cookieParts[i];
    while (cookiePart.charAt(0) === ' ') {
      cookiePart = cookiePart.substring(1);
    }
    if (cookiePart.indexOf(name) === 0) {
      return cookiePart.substring(name.length, cookiePart.length);
    }
  }
  return null;
}

export const getReferrerCookie = () => getCookie(REFERRER_COOKIE_NAME);
