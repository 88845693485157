import * as amplitude from 'amplitude-js';
import { getReferrerCookie, getCookie } from './cookies';

amplitude.getInstance().init('2532be1b0436a18cb938b21fc7fa9faf');

const userId = getCookie('builder.userId');

if (userId) {
  amplitude.getInstance().setUserId(userId);
  const { FS } = window as any;
  FS?.identify(userId);
}

function findAllParents(
  target: HTMLElement | SVGElement,
  callback: (element: HTMLElement | SVGElement) => boolean,
  checkElement = true
): (HTMLElement | SVGElement)[] {
  const parents: (HTMLElement | SVGElement)[] = [];
  if (!(target instanceof HTMLElement || target instanceof SVGElement)) {
    return parents;
  }
  let parent: HTMLElement | SVGElement | null = checkElement ? target : target.parentElement;
  do {
    if (!parent) {
      return parents;
    }

    const matches = callback(parent);
    if (matches) {
      parents.push(parent);
    }
  } while ((parent = parent.parentElement));

  return parents;
}

addEventListener(
  'mousedown',
  event => {
    const { target } = event;

    const TRACK_ATTRIBUTE = 'data-name';

    // TODO: track a[href] as well...? Selector for my own internal heatmaps?
    if (target instanceof HTMLElement || target instanceof SVGElement) {
      // data-uid, data-key, data-handle? key=? track=? handle=? uid=? bid=? b-id?
      const parents = findAllParents(target, el => el && !!el.getAttribute(TRACK_ATTRIBUTE));
      for (const parent of parents) {
        track('interaction', {
          type: 'mousedown',
          name: parent.getAttribute(TRACK_ATTRIBUTE),
        });
      }

      const aParents = findAllParents(target, el =>
        Boolean(el instanceof HTMLAnchorElement && el.href)
      ) as HTMLAnchorElement[];
      for (const parent of aParents) {
        track('interaction', {
          type: 'clickLink',
          detail: parent.getAttribute('href'),
          kind: parent.getAttribute('target'),
          text: parent.innerText.substring(0, 100),
        });
      }
    }
  },
  { passive: true, capture: true }
);

addEventListener('error', err => {
  track('error', {
    message: err.message,
    error: err.error && err.error.message,
    stack: err.error && err.error.stack,
    errorLocation: (err.filename || (err as any).source) + ':' + err.lineno + ':' + err.colno,
  });
});

export function fastClone<T>(obj: T): T { return JSON.parse(JSON.stringify(obj)) };

// TODO: share with app somehow
const sessionId = Math.random().toString(36).split('.')[1];

export function track(name: string, data = {} as any) {
  data = fastClone(data);

  const { Intercom, gtag, FS } = window as any;

  // Add these to top level in bigquery (?)
  data = {
    userId,
    url: location.href,
    app: 'forum',
    host: location.hostname,
    // TODO: get user data from firebase for tracking
    sessionId: sessionId,
    // TODO
    // organizationId: appState.user.organization && appState.user.organization.value.id,
    userLoggedIn: Boolean(userId),
    initialReferrer: getReferrerCookie(),
    timestamp: Date.now(),
    date: new Date().toUTCString(),
    sessionUrl:
      FS && typeof FS.getCurrentSessionURL === 'function' && FS.getCurrentSessionURL(true),
    ...data
  };

  trackInternal(name, data);

  amplitude.getInstance().logEvent(name, data);

  if (FS && typeof FS.event === 'function') {
    FS.event(name, fastClone(data));
  }

  const _hsq = ((window as any)._hsq = (window as any)._hsq || []);

  if (name === 'pageView') {
    _hsq.push(['trackPageView']);
  } else {
    _hsq.push(['trackEvent', { id: name }]);
  }

  if (typeof Intercom === 'function') {
    Intercom('trackEvent', name, data);
    if (name === 'pageView') {
      Intercom('update');
    }
  }
  if (typeof gtag === 'function') {
    gtag('send', {
      hitType: 'event',
      eventCategory: 'Site',
      eventAction: name,
    });
  }

  return true;
}

export function trackInternal(name: string, data?: any): void {
  fetch('https://builder.io/api/v1/event', {
    method: 'POST',
    body: JSON.stringify({ events: [{ name, data: flattenObject(data || {}) }] }),
    headers: {
      'content-type': 'application/json; charset=utf-8',
    },
  }).catch(error => {
    console.warn('Error @ `trackInternal`', error);
  });
}

function flattenObject(
  obj: any,
  _current?: any,
  _returnObject: any = {}
): { [key: string]: string } {
  for (const key of obj) {
    const value = obj[key];
    const newKey = _current ? _current + '.' + key : key;
    if (typeof value === 'object') {
      flattenObject(value, newKey, _returnObject);
    } else {
      _returnObject[newKey] = value;
    }
  }
  return _returnObject;
}
